.subtitulo-form, .titulo-form{
  text-align: center;
  font-weight: 600;
  color: #17bf9e;
}

form{
  
  padding: 40px 0;
  box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.8);
  border-radius: 15px;
}

.form{
  width: 100%;
  margin: auto;
}

form .grupo{
  position: relative;
  margin: 45px;
}

input{
  background: none;
  color: #c6c6c6;
  font-size: 22px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom:  1px solid #49454567;
}

input:focus{
  outline: none;
  color: #5e5d5d;

}

label{
  color: #49454567;
  font-size: 16px;
  position: absolute;
  left: 5px;
  top: 10px;
  transition: 0.5s ease-in-out all;
  pointer-events: none;
  margin-top: -20px;
}

input:focus~label,
input:valid~label
{
  top: -14px;
  font-size: 20px;
  color: #17bf9e;
}

.barra{
  position: relative;
  display: block;
  width: 100%;
}

.barra::before{
  content: "";
  height: 2px;
  width: 0%;
  bottom: 0;
  position: absolute;
  background: linear-gradient(to right, #b06ab3, #4568dc);
  transition: 0.3s ease width;
  left: 0;
  margin-top: -50px;
  
}

input:focus~.barra::before{
  width: 100%;
}

@media screen and (max-width:500px) {
  form{
    width: 80%;
  }
}