.i{
  display: flex;
  height: 100vh;
  background-image: url(../../images/01-home.jpg);  
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}

.i-left{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-right{
  flex: 1;
  position: relative;

}

.i-left-wrapper{
  padding: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.i-intro{
  font-size: 28px;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: 45px;
}

.i-name{
  font-size: 30px;
  text-transform: uppercase;
  margin-left: 45px;
  color: #59b256;
}
.i-title{
  height: 50px;
  overflow: hidden;
}

.i-title-wrapper{
  height: 100%;
  animation: move 20s ease-in-out infinite alternate;
}

@keyframes move{
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.i-title-item{
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #59b256;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-left: 45px;
}

.i-scroll {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 20px;
}

.i-img{
  width: 70%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}

.i-desc{
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .i {
    flex-direction: column;
  }

  .i-left-wrapper {  
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  .i-desc{
    display: none;
  }
  .i-img{
    width: 100%;
    margin-top: -130px;
  }
  .i-name{
    margin-right: 35px;
    margin-top: -100px;
    width: 100%;
  }  
  .i-title-item{
    font-size: 33px;
    margin-right: 30px;
  }

}