body {
  font-family: "Roboto", "sans-serif" ;
  background-color: #f8f8f8;
}

.text-biblic{
  font-size: 18px;
  letter-spacing: -1px;
  font-family: cursive;
  }

  .video{
    margin-top: 125px !important;
    border-radius: 25px;
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .choose__content{
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 80.25%; /* 16:9 Aspect Ratio */
  }