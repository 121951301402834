.footer {
  background: rgba(226, 248, 248, 0.664);
  color: #096687 !important;
}

h6{
  font-size: 23px;
  text-transform: uppercase;
}

.follows{
  margin-left: 10px;
}

.follows span a {
  text-decoration: none;
  margin: 5px;
  margin-left: 20px;
}

.follows span {
  margin-right: 15px;
}

.link__list .link__item {
  background: transparent;
}

.link__list a {
  text-decoration: none;
  color: #096687;
  font-weight: 500;
}

.icon{
  margin: 5px;
  height: 32px;
  width: 32px;
  font-size: 28px;
  color: #27ae60;
  }

i:hover{
  color: #096687;
}