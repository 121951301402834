.beneflex-hero{
  display: flex;
  height: 100vh;
  background-image:  url("../../images/portadabeneflex01.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

.hero, .hero-ingles, .hero-naturalizacion, .hero-ozias, .hero-referencias{
  background-color:  rgba(0,0,0,.1);
  color: #27ae60;
  height: 80vh;
  width: 100%;
  position: relative;
}

.hero:before {
  content: '';
  background: url('../../images/PORTADA-FINANZAS.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: left;
}

.hero-ingles:before {
  content: '';
  background: url('../../images/PORTADA-SINTEXTO-CURSOS-DE-INGLÉS.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: left;
}
.hero-naturalizacion:before {
  content: '';
  background: url('../../images/PORTADA-SINTEXTO-CURSOS-DE-CIUDADANÍA.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: left;
}
.hero-ozias:before {
  content: '';
  background: url('../../images/PORTADA-SINTEXTO-ozias.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: left;
}

.hero-referencias:before {
  content: '';
  background: url('../../images/PORTADA-SINTEXTO-REFERENCIAS.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: left;
}
.hero .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  padding: 1rem;
}

.hero .content p:first-child {
  font-style: italic;
  font-size: 2.9rem;
  text-transform: uppercase;
  margin-top: -130px;
  font-weight: 400;
  text-decoration: underline;
  
}
.hero .content p:nth-child(2) {
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-left:50px;

}
.hero .content p span {
  font-size: 22px;
  font-weight: 500;
  margin-left: 20px;
  text-transform: uppercase;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #34b9cb;
  
}
.hero-ingles .content-ingles {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  padding: 1rem;
}


.hero-ingles .content-ingles p:first-child {
  font-style: italic;
  font-size: 2.9rem;
  text-transform: uppercase;
  margin-top: -130px;
  font-weight: 400;
  text-decoration: underline; 
}
.hero-ingles .content-ingles p:nth-child(2) {
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-left:50px;
}
.hero-ingles .content-ingles p span {
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
  text-transform: uppercase;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #34b9cb;
}


.hero-naturalizacion .content-naturalizacion {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  padding: 1rem;
}


.hero-naturalizacion .content-naturalizacion p:first-child {
  font-style: italic;
  font-size: 2.9rem;
  text-transform: uppercase;
  margin-top: -130px;
  font-weight: 400;
  text-decoration: underline; 
}
.hero-naturalizacion .content-naturalizacion p:nth-child(2) {
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-left:50px;
}
.hero-naturalizacion .content-naturalizacion p span {
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
  text-transform: uppercase;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #34b9cb;
}


.hero-ozias .content-ozias {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  padding: 1rem;
}
.hero-ozias .content-ozias p:first-child {
  font-style: italic;
  font-size: 2.9rem;
  text-transform: uppercase;
  margin-top: -130px;
  font-weight: 400;
  text-decoration: underline; 
}
.hero-ozias .content-ozias p:nth-child(2) {
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-left:50px;
}
.hero-ozias .content-ozias p span {
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
  text-transform: uppercase;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #34b9cb;
}


.hero-referencias .content-referencias {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  padding: 1rem;
}
.hero-referencias .content-referencias p:first-child {
  font-style: italic;
  font-size: 2.9rem;
  text-transform: uppercase;
  margin-top: -130px;
  font-weight: 400;
  text-decoration: underline; 
}
.hero-referencias .content-referencias p:nth-child(2) {
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-left:50px;
}
.hero-referencias .content-referencias p span {
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
  text-transform: uppercase;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #34b9cb;
}



.acordion, .about__img{
  margin-top: 100px;
}

img{
  border-radius: 40px;
}
.slider{
   font-size: 35px;
   margin-top: 50px;
}

.slider-referencias{
  margin-top: 100px !important;
  font-size: 30px;
  
}
.w-100{
  margin-top: 50px;
  width: 90%;
}

.toast{
  margin-top: 30px;
}

.newsletter {
  background: #17bf9e;
  height: 80%;
  padding: 60px;
  margin-top: 80px !important;
  border-radius: 15px;
}

.newsletter h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
}
.subscribe input {
  border: none;
  outline: none;
}

.subscribe {
  padding: 7px 10px;
  background: #fff;
  width: 45%;
  margin: auto;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width:768px) {
  .hero .content p:first-child {
      font-size: 2.6rem;
      margin-left: 40px;      
  }

  .hero .content p:nth-child(2) {
    flex-direction: column;
    width: 60%;
    margin-left: 70px;
    font-weight: 700;
  }
  .hero-ingles .content-ingles p:first-child {
    font-size: 2.6rem;
    margin-left: 40px;      
}

.hero-ingles .content-ingles p:nth-child(2) {
  flex-direction: column;
  width: 60%;
  margin-left: 70px;
  font-weight: 700;
}

.hero-naturalizacion .content-naturalizacion p:first-child {
  font-size: 2.6rem;
  margin-left: 40px;      
}

.hero-naturalizacion .content-naturalizacion p:nth-child(2) {
flex-direction: column;
width: 60%;
margin-left: 70px;
font-weight: 700;
}

.hero-ozias .content-ozias p:first-child {
  font-size: 2.6rem;
  margin-left: 40px;      
}

.hero-ozias .content-ozias p:nth-child(2) {
flex-direction: column;
width: 60%;
margin-left: 70px;
font-weight: 700;
}
.hero-referencias .content-referencias p:first-child {
  font-size: 2.6rem;
  margin-left: 40px;      
}

.hero-referencias .content-referencias p:nth-child(2) {
flex-direction: column;
width: 59%;
margin-left: 70px;
font-weight: 700;
}




}