.a{
  height: 100vh;
  display: flex;
  align-items: center;
}
.a-left{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}
.a-right{
  flex: 1;
}

.a-card.bg{
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #A4F7FA;
}


.a-card{
  width: 80%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.a-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-award-img {
  width: 30%;
  height: 30%;
  border-radius: 20px;
  object-fit: cover;
}

.a-title {
  font-weight: 800;
  font-size: 28px;
}

.a-sub {
  margin: 20px 0px;
  font-size: 24px;
  font-weight: 400;
}

.a-desc{
    font-weight: 300;
}

.a-award{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.a-award-texts{
    width: 70%;
}

.a-award-title{
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;
}

@media screen and (max-width:480px) {
    .a{
      flex-direction: column;
      text-align: center;
      margin-top: 50px;
    }

    .a-left {
      width: 100%;
    }

    .a-card {
      height: 30vh;
    }

    .a-card.bg, .a-award {
      display: none;
    }

    .a-right{
      padding: 20px;
    }
}