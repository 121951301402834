.single__course__item {
  padding: 15px;
}

.course__img img {
  border-radius: 10px;
  margin-bottom: 20px;
}

.enroll a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #17bf9e;
}

.lesson i,
.students i,
.rating i {
  font-weight: 400;
}

.course__title {
  line-height: 2rem;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  color: #17bf9e;
}



.course__top__left h2 {
  font-weight: 600;
  text-align: center;
  font-size: 25px;
  margin-left: -18px !important;
}

  
.course__top__left p {
  text-align: center;
  font-size: 18px;
  text-align: justify;
  font-weight: 600;
  margin-left: -25px !important;
}

  
.course__top__left h3 {
  font-weight: 300;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
h2{
 text-align: center;
 color: #17bf9e;
 margin-left: -10px !important;
}

.container {
  max-width: 85%;
  margin: auto;
}

.row{
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}


@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
}


